import React from "react"

import SEO from "../components/seo/seo"
import Header from '../components/header/header';

const StorePage = () => (
  <>
    <SEO title="Store" />
    <Header
      title="Store" 
    />
    <div className="container-fluid pt-3 content">
      <div className="row">
        <div className="col">
        </div>
      </div>
    </div>
  </>
)

export default StorePage
